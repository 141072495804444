<template>
  <div class="portfolio-item-wrapper">
    <div ref="portfolioItem" class="portfolio-item">
      <div ref="portfolioItemMisBg" class="portfolio-item-mis-bg"></div>
      <div ref="portfolioItemMisFg" class="portfolio-item-mis-fg"></div>
      <div
        ref="portfolioItemBackgroundBlur"
        class="portfolio-item-background-blur">
        <picture
          :style="{
            background:
              'url(' +
              responsiveBackgroundImage.placeholder +
              ') ' +
              backgroundPositionX +
              '% ' +
              backgroundPositionY +
              '% / cover',
          }">
          <source
            :srcSet="responsiveBackgroundImage.placeholder"
            type="image/jpg" />
          <img
            :src="responsiveBackgroundImage.placeholder"
            :width="responsiveBackgroundImage.width"
            :height="responsiveBackgroundImage.height"
            sizes="(min-width: 1024px) 1024px, 100vw" alt="A blurred poster image" />
        </picture>
      </div>
      <div ref="portfolioItemBackground" class="portfolio-item-background">
        <picture
          ref="backgroundPicture"
          :style="{
            background:
              'url(' +
              responsiveBackgroundImage.placeholder +
              ') ' +
              backgroundPositionX +
              '% ' +
              backgroundPositionY +
              '% / cover',
          }">
          <source :srcSet="responsiveBackgroundImage.srcSet" type="image/jpg" />
          <img
            :src="responsiveBackgroundImage.src"
            :srcSet="responsiveBackgroundImage.srcSet"
            :width="responsiveBackgroundImage.width"
            :height="responsiveBackgroundImage.height"
            sizes="(min-width: 1024px) 1024px, 100vw"
            @load="removeBlur"
            alt="A background poster image" />
        </picture>
        <div ref="portfolioItemLogo" class="portfolio-item-logo">
          <img :src="require(`@/assets/images/portfolio/${logo}`)" alt="A companies logo" />
        </div>
      </div>
    </div>
    <div ref="itemDescription" class="item-description">
      <ul>
        <li v-for="workTitle in workTitles" :key="workTitle.title">
          {{ workTitle }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { gsap } from 'gsap/all'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import '../../assets/scripts/blur'

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger)

export default {
  beforeUnmount() {
    if (this.$refs.portfolioItem.vanillaTilt) {
      this.$refs.portfolioItem.vanillaTilt.destroy()
    }
  },
  props: {
    backgroundImageFileName: { type: String, required: true },
    logo: { type: String, required: true },
    backgroundPositionX: { type: Number, default: 0 },
    backgroundPositionY: { type: Number, default: 0 },
    workTitles: {
      type: Object,
      default: function () {
        return { title: 'A Test', title2: '2nd Test' }
      },
    },
  },
  data() {
    return {
      glare: false,
      maxEffect: 6,
      scale: 1.5,
      portfolioItemTL: null,
    }
  },
  computed: {
    responsiveBackgroundImage() {
      let responsiveBackgroundImage = require(`@/assets/images/portfolio/${this.backgroundImageFileName}`)
      return responsiveBackgroundImage
    },
  },
  methods: {
    removeBlur() {
      if (this.$refs.backgroundPicture) {
        this.$refs.backgroundPicture.classList.add('is-loaded')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
$portfolio-item-width: 30vmin;
$portfolio-item-height: 49vmin;
$portfolio-item-margin-bottom: 12vh;
$portfolio-item-margin-right: 5vmin;

.portfolio-item {
  width: $portfolio-item-width;
  height: $portfolio-item-height;
  white-space: nowrap;
  transform-style: preserve-3d;
  margin-bottom: $portfolio-item-margin-bottom !important;
  margin-right: $portfolio-item-margin-right !important;
  perspective-origin: 0 0;
  transform: perspective(1500px);
  overflow: hidden;
  margin-left: 5vmin;

  @media #{$mq-tiny} {
    width: 60vw;
    height: 48vh;
    margin-top: 11vmin !important;
  }
}

.item-description {
  margin-top: -11.5vh;
  margin-left: 7.5vmin;
  margin-right: 2.1vmin;
  position: absolute;
  font-size: 0.7em;
  line-height: 1.7em;
  color: rgb(202, 202, 202);
  font-family: $main-font;
  opacity: 0;


@media #{$mq-small} {
    font-size: 0.4em;
}
@media #{$mq-medium} {
    font-size: 0.4em;
}
@media #{$mq-large} {
    font-size: 0.4em;
}

@media #{$mq-xlarge} {
    font-size: 0.4em;
}

}

.item-description > ul {
  list-style-type: none;
}

.portfolio-item-mis-bg {
  width: $portfolio-item-width;
  height: $portfolio-item-height;
  background-color: $makeitsopurple;
  position: fixed;

  @media #{$mq-tiny} {
    width: 60vw;
    height: 48vh;
  }
}
.portfolio-item-mis-fg {
  width: $portfolio-item-width;
  height: $portfolio-item-height;
  background-color: $makeitsopurple;
  position: absolute;
  transform-origin: 'top left';
  transform: rotate(45deg) scaleY(2.2) scaleX(2.2);
  opacity: 0;
  z-index: 30;

  @media #{$mq-tiny} {
    width: 60vw;
    height: 48vh;
  }
}

.portfolio-item-background {
  position: relative;
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  opacity: 0;
  z-index: 3;
}

.portfolio-item-background:after {
  position: absolute;
  height: 100%;
  width: 100%;
  content: '';
  background-color: rgba(255, 255, 255, 0.17);
  top: 0;
  left: 0;
}

.portfolio-item-background picture > img {
  outline: 1px solid transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.portfolio-item-background picture {
  outline: 1px solid transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  filter: blur(6px);
}

.portfolio-item-background > picture.is-loaded {
  filter: none; /* remove the blur on fullres image */
  transition: filter 1.5s;
}

.portfolio-item-background-blur {
  width: $portfolio-item-width;
  height: $portfolio-item-height;
  position: absolute;
  top: -1px;
  left: -10px;
  transform: perspective(0px);
  opacity: 0;
  transform-origin: 0 0;
  transform: translateZ(-30px);
  filter: blur(30px);
  @media #{$mq-tiny} {
    width: 60vw;
    height: 48vh;
  }
}

.portfolio-item-background-blur picture > img {
  width: $portfolio-item-width;
  height: $portfolio-item-height;
  object-fit: cover;
  margin-left: 2vh;
  margin-top: 2vh;
  filter: blur(20px);
  transform: perspective(0);
  opacity: 0.9;
  @media #{$mq-tiny} {
    width: 60vw;
    height: 48vh;
  }
}

.portfolio-item-logo {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  overflow: visible;
  transform-origin: 0 0;
  transform: translateZ(50px);
  opacity: 0;
  z-index: 3;
  filter: drop-shadow(0rem 0rem 0.2rem rgba(42, 42, 42, 0.459));
}
.portfolio-item-logo > img {
  outline: 1px solid transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent !important;
}
</style>
